import React from 'react';
import { Link } from 'gatsby';
import Container from './Container';
import smallLogo from '../images/planl-logo-small.png';

const SMALL_MODE_HEIGHT = '@media (max-height: 750px)';

const MenuLink = ({ children, to }) => (
  <Link
    style={{
      color: 'white',
      textDecoration: 'none',
      fontWeight: 400,
      textAlign: 'right'
    }}
    to={to}
  >
    {children}
  </Link>
);

const MenuItem = ({ children }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%',
      color: 'white'
    }}
  >
    {children}
  </div>
);

const Logo = () => (
  <Link
    css={{
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: 'white',
      height: '100%'
    }}
    to="/"
  >
    <img
      src={smallLogo}
      alt="Logo"
      css={{
        height: 100,
        marginBottom: -50,
        [SMALL_MODE_HEIGHT]: {
          height: 60,
          marginBottom: -25
        }
      }}
    />
    <h1
      css={{
        margin: 0,
        padding: 0,
        marginLeft: 15
      }}
    >
      Planl
    </h1>
  </Link>
);

export default function Menu() {
  return (
    <div
      css={{
        height: 100,
        backgroundColor: '#51D2C2',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        overflow: 'hidden',
        [SMALL_MODE_HEIGHT]: {
          height: 60
        }
      }}
    >
      <Container
        customCSS={{
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Logo />
        <MenuItem>
          <MenuLink to="/info">Wat is Planl?</MenuLink>
        </MenuItem>
      </Container>
    </div>
  );
}
