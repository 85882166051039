// @flow
import React from 'react';

const SMALL_MODE_WIDTH = '@media (max-width: 800px)';

// Encode so we can send it to netlify
const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const Field = props => (
  <div
    css={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20
    }}
  >
    <label
      htmlFor={props.name}
      css={{
        fontWeight: props.hasError ? 600 : 400,
        color: props.hasError ? 'red' : 'black'
      }}
    >
      {props.label}
    </label>
    <input
      type={props.type}
      name={props.name}
      css={{
        display: 'block',
        width: '100%',
        lineHeight: '1.2em',
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: 5,
        padding: 10,
        fontSize: '1.1rem',
        borderColor: '#deecff',
        boxSizing: 'border-box'
      }}
      onChange={props.onChange}
    />
  </div>
);

const TextArea = props => (
  <div
    css={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20
    }}
  >
    <label htmlFor={props.name} css={{ fontWeight: 400 }}>
      {props.label}
    </label>
    <textarea
      name={props.name}
      css={{
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: 5,
        padding: 10,
        fontSize: '1.2em',
        borderColor: '#deecff'
      }}
      rows="5"
      onChange={props.onChange}
    />
  </div>
);

export default class ContactForm extends React.Component {
  state = {
    name: '',
    email: '',
    providerName: '',
    phoneNumber: '',
    message: '',
    wrongFields: [],
    formSent: false
  };

  handleChange = event => this.setState({ [event.target.name]: event.target.value });

  // SUbmit to netlify
  handleSubmit = event => {
    const requiredFields = ['name', 'email', 'providerName'];
    const wrongFields = [];
    requiredFields.forEach(requiredField => {
      if (!this.state[requiredField]) {
        wrongFields.push(requiredField);
      }
    });

    if (wrongFields.length) {
      this.setState({ wrongFields });
      event.preventDefault();
      return;
    }
    this.setState({ wrongFields: [] });

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state })
    })
      .then(() => {
        this.setState({ formSent: true });
        window.scroll(0, 0);
      })
      .catch(error => {
        console.error(error);
      });

    event.preventDefault();
  };

  render() {
    return (
      <div
        css={{
          flex: 5,
          marginRight: 100,
          marginBottom: 50,
          [SMALL_MODE_WIDTH]: {
            minWidth: '100%'
          }
        }}
      >
        {this.state.wrongFields.length > 0 && (
          <p css={{ fontWeight: 600, marginBottom: 25, color: 'red' }}>
            Er missen nog enkele velden. Vul alsjeblieft de roodgekleurde velden in en probeer het
            opnieuw.
          </p>
        )}

        {this.state.formSent && (
          <p css={{ fontWeight: 600, marginBottom: 25 }}>
            Bedankt! We nemen zo snel mogelijk contact met je op.
          </p>
        )}

        <form
          name="contact"
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label htmlFor="bot-field">
              Vul dit niet in:
              <input id="bot-field" name="bot-field" />
            </label>
          </p>
          <Field
            label="Naam"
            name="name"
            type="text"
            hasError={this.state.wrongFields.indexOf('name') > -1}
            onChange={this.handleChange}
          />
          <Field
            label="Email"
            name="email"
            type="email"
            hasError={this.state.wrongFields.indexOf('email') > -1}
            onChange={this.handleChange}
          />
          <Field
            label="Naam van de praktijk"
            name="providerName"
            type="text"
            hasError={this.state.wrongFields.indexOf('providerName') > -1}
            onChange={this.handleChange}
          />
          <Field
            label="Telefoonnummer (niet verplicht, wel handig)"
            name="phoneNumber"
            type="tel"
            onChange={this.handleChange}
          />
          <TextArea
            label="Extra bericht voor ons (laat gerust leeg)"
            name="message"
            onChange={this.handleChange}
          />
          <p>
            <button
              type="submit"
              css={{
                backgroundColor: '#51D2C2',
                fontWeight: 600,
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                cursor: 'pointer',
                textDecoration: 'none',
                border: 'none',
                padding: '15px 25px',
                display: 'inline-block',
                borderRadius: 5,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                fontSize: '1.2em',
                marginTop: 10
              }}
            >
              Verstuur
            </button>
          </p>
        </form>
      </div>
    );
  }
}
