// @flow
import React from 'react';
import Helmet from 'react-helmet';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';
import Container from '../../components/Container';
import PlanlLogo from '../../images/planl-logo.png';
import ContactForm from './ContactForm';

export default function Info() {
  return (
    <div>
      <Helmet
        title="Meer informatie over Planl voor zorgverleners aanvragen"
        meta={[
          {
            name: 'description',
            content: 'Informatie over Planl voor zorgverleners'
          },
          {
            name: 'keywords',
            content:
              'Afspraak maken,Planl,zorgverleners,informatie,tandarts,huisarts,fysio,contact,email'
          }
        ]}
      />
      <div
        css={{
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column'
        }}
      >
        <Menu />
        <Container
          customCSS={{
            flex: 1,
            position: 'relative',
            paddingBottom: 300,
            overflow: 'hidden'
          }}
        >
          <h1
            css={{
              color: 'black',
              fontSize: '2em',
              fontWeight: 400,
              lineHeight: '1.35em',
              marginTop: 50
            }}
          >
            Meer informatie over Planl voor zorgverleners
          </h1>
          <p css={{ marginTop: 10 }}>
            Meer weten over Planl? Laat je emailadres achter en we nemen contact met je op om de
            mogelijkheden te bespreken. Indien je geïnteresseerd bent in een online demo dan horen
            we dat graag!
          </p>

          <div css={{ display: 'flex', marginTop: 25, flexWrap: 'wrap' }}>
            <ContactForm />

            <div css={{ flex: 5 }}>
              <b css={{ fontWeight: 600 }}>Enkele voordelen van Planl</b>
              <ul css={{ lineHeight: '1.5em', listStyleType: 'disc' }}>
                <li css={{ margin: 10 }}>
                  Patiënten kunnen 24/7 direct digitaal een afspraak maken, wanneer jij dat wilt.
                </li>
                <li css={{ margin: 10 }}>
                  Veiligheid is prioriteit nummer één; bij Planl gaat alles via een versleutelde
                  verbinding.
                </li>
                <li css={{ margin: 10 }}>
                  Volledige controle over jouw agenda. Afspraken kun je goedkeuren, afkeuren of
                  wijzigen als dat beter uitkomt. Laat een bericht achter voor de patiënt met uitleg
                  waarom je een bepaalde actie hebt ondernomen.
                </li>
                <li css={{ margin: 10 }}>
                  Profiteer van de voordelen van het Planl netwerk en bereik meer patiënten.
                </li>
              </ul>
            </div>
          </div>

          <img
            src={PlanlLogo}
            alt=""
            css={{
              position: 'absolute',
              width: 200,
              margin: '0 auto',
              left: 0,
              right: 0,
              bottom: -50
            }}
          />
        </Container>
        <Footer />
      </div>
    </div>
  );
}
